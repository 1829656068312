import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/globals.scss";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import App from "./App";
import InstallPage from "./pages/InstallPage";

const router = createBrowserRouter([
	{
		path: "/",
		element: <InstallPage />,
	},
	{
		path: "/pwa",
		element: <App />,
	},
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<RouterProvider router={router} />);
