import React, { useState } from "react";
import css from "../styles/InstallPage.module.scss";
import classNames from "classnames";

const IOS = ({ iosActive, setIosActive }) => {
	const [lang, setLang] = useState(false);

	return (
		<div className={classNames(css.iosContent, iosActive && css._active)}>
			<header className={classNames(css.head, "head")}>
				<div className={css.logo} />
				<div
					className={css.languages}
					onClick={() => setLang(!lang)}
				>
					<div className={css.languageContainer}>
						<div className={classNames(css.language, !lang && css._active)}>ENG</div>
						<div className={classNames(css.language, lang && css._active)}>HIN</div>
					</div>
				</div>
			</header>
			<section className={css.body}>
				<div className={css.backContainer}>
					<button
						className={css.back}
						onClick={() => setIosActive(false)}
					>
						SOFT
					</button>
				</div>
				<h1 className={css.title}>
					{!lang ? "how to download the app for ios" : "iOS ke liye app kaise download karein"}
				</h1>
				<div className={css.step}>
					<div className={css.num}>1</div>
					<div className={css.stepTitle}>
						{!lang ? "Click on this button" : "Is button par click karein"}
					</div>
				</div>
				<div className={css.imageContainer}>
					<img
						src="images/slideForInstall/d1.png"
						alt=""
					/>
				</div>
				<div className={css.step}>
					<div className={css.num}>2</div>
					<div className={css.stepTitle}>
						{!lang ? "Find “Add to Home Screen”" : "“Add to Home Screen” dhoondein"}
					</div>
				</div>
				<div className={css.imageContainer}>
					<img
						src="images/slideForInstall/d2.png"
						alt=""
					/>
				</div>
				<div className={css.step}>
					<div className={css.num}>3</div>
					<div className={css.stepTitle}>
						{!lang
							? "And application icon will appear on your screen"
							: "Aur application ka icon aapki screen par dikhai dega"}
					</div>
				</div>
			</section>
		</div>
	);
};

export default IOS;
