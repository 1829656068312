import React, { useEffect, useRef } from "react";

const VideoId = ({ setShowVideo }) => {
	const videoRef = React.useRef(null);

	return (
		<div
			style={{
				position: "fixed",
				left: "0",
				top: "0",
				width: "100%",
				height: "100vh",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				backgroundColor: "rgb(39, 40, 50)",
				zIndex: "1001",
			}}
		>
			<div
				onClick={() => setShowVideo(false)}
				style={{
					position: "absolute",
					left: "30px",
					top: "30px",
					width: "30px",
					height: "30px",
					backgroundImage: `url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNSIgaGVpZ2h0PSIxMCIgZmlsbD0ibm9uZSIgdmlld0JveD0iMCAwIDE1IDEwIj48cGF0aCBmaWxsPSIjZmZmIiBkPSJNMTQgNC40YS42LjYgMCAxIDEgMCAxLjJWNC40Wk0uNTc2IDUuNDI0YS42LjYgMCAwIDEgMC0uODQ4TDQuMzk0Ljc1N2EuNi42IDAgMCAxIC44NDkuODQ5TDEuODQ5IDVsMy4zOTQgMy4zOTRhLjYuNiAwIDEgMS0uODQ5Ljg0OUwuNTc2IDUuNDI0Wk0xNCA1LjZIMVY0LjRoMTN2MS4yWiIvPjwvc3ZnPg==")`,
					backgroundRepeat: "no-repeat",
					backgroundPosition: "center",
					backgroundSize: "contain",
				}}
			/>
			<video
				ref={videoRef}
				style={{ height: "75%" }}
				muted
				loop={true}
				autoPlay
				controls
			>
				<source
					src="images/TUTOR_ID.mp4"
					type="video/mp4"
				/>
			</video>
		</div>
	);
};

export default VideoId;
