import React, { useEffect, useContext } from "react";
import css from "../../styles/OtherPages.module.scss";
import classNames from "classnames";
import numeral from "numeral";
import { Context } from "../../App";

const HowToPlay = ({ menu, setMenu, percent = 0, leadData }) => {
	const { language } = useContext(Context);

	return (
		<div className={classNames(css.container, menu === 3 && css._show)}>
			<div className={css.content}>
				<section className={css.backContainer}>
					<button
						className={css.back}
						onClick={() => setMenu(1)}
					>
						SOFT
					</button>
				</section>

				<section className={css.howPlayContainer}>
					<div className={css.title}>
						{!language && (
							<>
								What is a <span>Chance</span> and <br /> How Does it Work
							</>
						)}
						{!!language && (
							<>
								<span>Chance</span> kya hai aur yeh <br /> kaise kaam karta hai
							</>
						)}
					</div>
					{!language && (
						<p className={css.text}>
							Our software is your key to success in the
							<br />
							Aviator game. We help predict multipliers,
							<br />
							turning luck into strategy. Control the
							<br /> game, increasing your chances of winning.
							<br /> With our software, you will always be one
							<br /> step ahead.
						</p>
					)}
					{!!language && (
						<p className={css.text}>
							Humara software Aviator game mein aapki
							<br /> success ki key hai. Hum multipliers ko predict karne
							<br /> mein madad karte hain, taaki aap apni luck ko
							<br /> strategy mein badal sakein. Game ko
							<br /> control karo, apne jeetne ke chances badhao.
							<br /> Hamare software ke saath, aap
							<br /> hamesha ek kadam aage rahenge.
						</p>
					)}
				</section>
				<section className={classNames(css.howPlayContainer, css.howPlay)}>
					{!language && (
						<div className={css.title}>
							How Multipliers Affect <br /> Your Win and Why Use the
							<br /> <span>"Previous Round"</span> Block
						</div>
					)}
					{!!language && (
						<div className={css.title}>
							Multipliers kaise aapki jeet
							<br /> ko affect karte hain aur
							<br /> <span>"Previous Round"</span> block
							<br /> ka kyun use karein
						</div>
					)}
					{!language && (
						<p className={css.text}>
							To <span>increase your chances of winning</span>
							<br /> in Aviator, follow two simple steps:
						</p>
					)}
					{!!language && (
						<p className={css.text}>
							Aviator mein <span>apne jeetne ke chances badhane ke liye</span>
							<br /> do simple steps follow karo:
						</p>
					)}
					<div className={css.step}>
						<div className={css.num}>1</div>
						{!language && (
							<p>
								<span>Top up your balance</span> — more funds increase the likelihood of hitting a
								profitable multiplier.
							</p>
						)}
						{!!language && (
							<p>
								<span>Apne balance ko top-up karo</span> — zyada funds ka matlab hai zyada
								profitable multiplier ka chance.
							</p>
						)}
					</div>
					<div className={css.step}>
						<div className={css.num}>2</div>
						{!language && (
							<p>
								<span>Click “Increase Chance”</span> — activate this mode to boost your chances of
								success.
							</p>
						)}
						{!!language && (
							<p>
								<span>"Increase Chance" par click karo</span> — iss mode ko activate karke apni
								success ke chances badhao.
							</p>
						)}
						<div className={css.imageIncraceChance} />
					</div>
					{!language && (
						<p className={css.text}>
							Use the "Previous Round" block to see the statistics of previous rounds and choose the
							best multipliers. The higher your balance, the greater the chance to make the right
							choice and win!
						</p>
					)}
					{!!language && (
						<p className={css.text}>
							"Previous Round" block ka use karke pichle rounds ki statistics dekho aur best
							multipliers choose karo. Jitna zyada balance hoga, utna zyada chance hoga sahi choice
							karne ka aur jeetne ka!
						</p>
					)}
					<div className={css.arrowBottom} />
					<div className={css.imagePrevRound} />
				</section>
				<section className={classNames(css.howPlayContainer, css.howPlay)}>
					<div className={css.step}>
						<div className={css.num}>1</div>
						{!language && <p>Click "Increase Chance".</p>}
						{!!language && <p>"Increase Chance" par click karo.</p>}
					</div>
					<div className={css.step}>
						<div className={css.num}>2</div>
						{!language && <p>Top up your balance.</p>}
						{!!language && <p>Apna balance top-up karo.</p>}
					</div>
					<div className={css.step}>
						<div className={css.num}>3</div>
						{!language && (
							<p>
								<span>Increase your chance to win</span> and earn even more!
							</p>
						)}
						{!!language && (
							<p>
								<span>Apne jeetne ke chances badhao</span> aur aur bhi zyada kamao!
							</p>
						)}
					</div>
				</section>
				<section className={classNames(css.howPlayContainer, css.howPlay)}>
					{!language && (
						<div className={css.title}>
							Round States
							<br /> and Their Meaning
						</div>
					)}
					{!!language && (
						<div className={css.title}>
							Round States
							<br /> aur unka matlab
						</div>
					)}
				</section>
				<section className={classNames(css.howPlayContainer, css.howPlay)}>
					<div className={css.titleStep}>
						<span>1</span>
						WAITING
					</div>
				</section>
				<section className={classNames(css.howPlayContainer, css.howPlay)}>
					{!language && (
						<div
							className={css.text}
							style={{ marginTop: "0px" }}
						>
							When “waiting” is displayed, it’s a signal to get ready. Memorize the “predict”
							multiplier and be prepared to hit “Cash Out” at the right moment to lock in your win.
						</div>
					)}
					{!!language && (
						<div
							className={css.text}
							style={{ marginTop: "0px" }}
						>
							Jab "waiting" display hota hai, to yeh signal hai ki ready ho jao. "Predict"
							multiplier yaad rakho aur sahi waqt pe "Cash Out" pe click karne ke liye tayar raho
							taaki aapki jeet pakki ho.
						</div>
					)}
					<div className={css.howPlayImage}>
						<img
							src="images/howPlay/1.png"
							alt=""
						/>
					</div>
				</section>
				<section className={classNames(css.howPlayContainer, css.howPlay)}>
					<div className={css.titleStep}>
						<span>2</span>
						ROUND IS ON
					</div>
				</section>
				<section className={classNames(css.howPlayContainer, css.howPlay)}>
					{!language && (
						<div
							className={css.text}
							style={{ marginTop: "0px" }}
						>
							The plane has taken off, and the multipliers are rising. The longer you wait, the
							greater the potential win.
						</div>
					)}
					{!!language && (
						<div
							className={css.text}
							style={{ marginTop: "0px" }}
						>
							Plane ud chuka hai aur multipliers badh rahe hain. Jitna der tak intezaar karoge, utna
							bada potential win hoga.
						</div>
					)}
					<div className={css.howPlayImage}>
						<img
							src="images/howPlay/2.png"
							alt=""
						/>
					</div>
				</section>
				<section className={classNames(css.howPlayContainer, css.howPlay)}>
					<div className={css.titleStep}>
						<span>3</span>
						WINNER ROUND IS ON
					</div>
				</section>
				<section className={classNames(css.howPlayContainer, css.howPlay)}>
					{!language && (
						<div
							className={css.text}
							style={{ marginTop: "0px" }}
						>
							You’re already in profit, but the multiplier keeps growing. Lock in your profit before
							it’s too late!
						</div>
					)}
					{!!language && (
						<div
							className={css.text}
							style={{ marginTop: "0px" }}
						>
							Aap already profit mein ho, lekin multiplier badhta jaa raha hai. Apna profit lock kar
							lo, kahin der na ho jaye!
						</div>
					)}
					<div className={css.howPlayImage}>
						<img
							src="images/howPlay/3.png"
							alt=""
						/>
					</div>
				</section>
				<section className={classNames(css.howPlayContainer, css.howPlay)}>
					<div className={css.titleStep}>
						<span>4</span>
						FLEW OFF WIN
					</div>
				</section>
				<section className={classNames(css.howPlayContainer, css.howPlay)}>
					{!language && (
						<div
							className={css.text}
							style={{ marginTop: "0px" }}
						>
							You cashed out in time and won! Congratulations!
						</div>
					)}
					{!!language && (
						<div
							className={css.text}
							style={{ marginTop: "0px" }}
						>
							Aapne time pe cash-out kar diya aur jeet gaye! Mubarak ho!
						</div>
					)}
					<div className={css.howPlayImage}>
						<img
							src="images/howPlay/4.png"
							alt=""
						/>
					</div>
				</section>
				<section className={classNames(css.howPlayContainer, css.howPlay)}>
					<div className={css.titleStep}>
						<span>5</span>
						FLEW OFF LOSE
					</div>
				</section>
				<section className={classNames(css.howPlayContainer, css.howPlay)}>
					{!language && (
						<div
							className={css.text}
							style={{ marginTop: "0px" }}
						>
							You lost because your chances were low. To increase them, click “Increase Chance,”
							follow the actions shown in the modal window, and you’ll start earning more!
						</div>
					)}
					{!!language && (
						<div
							className={css.text}
							style={{ marginTop: "0px" }}
						>
							Aap haar gaye kyunki aapke chances kam the. Inhe badhane ke liye "Increase Chance" pe
							click karo, modal window mein diye gaye actions follow karo, aur aap zyada kamaana
							shuru karenge!
						</div>
					)}
					<div className={css.howPlayImage}>
						<img
							src="images/howPlay/5.png"
							alt=""
						/>
					</div>
				</section>
				<section className={classNames(css.howPlayContainer, css.howPlay)}>
					<div className={css.titleStep}>
						<span>6</span>
						SYNC
					</div>
				</section>
				<section className={classNames(css.howPlayContainer, css.howPlay)}>
					{!language && (
						<div
							className={css.text}
							style={{ marginTop: "0px" }}
						>
							The system is preparing for the next round. Get ready, plan your strategy, and wait
							for your next chance to win.
						</div>
					)}
					{!!language && (
						<div
							className={css.text}
							style={{ marginTop: "0px" }}
						>
							System next round ke liye prepare ho raha hai. Tayaar raho, apni strategy banao, aur
							apne next chance ka intezaar karo.
						</div>
					)}
					<div className={css.howPlayImage}>
						<img
							src="images/howPlay/6.png"
							alt=""
						/>
					</div>
				</section>
				<section className={classNames(css.howPlayContainer, css.howPlay)}>
					{!language && (
						<div
							className={css.title}
							style={{ fontSize: "16px" }}
						>
							Each round is an opportunity <br /> to win. Increase your balance <br /> and play with
							confidence!
						</div>
					)}
					{!!language && (
						<div
							className={css.title}
							style={{ fontSize: "16px" }}
						>
							Har round ek opportunity hai
							<br /> jeetne ki. Apna balance badhao aur
							<br /> confidence ke saath khelo!
						</div>
					)}
				</section>
				<section
					className={css.buttons}
					style={{ margin: "20px 0px" }}
				>
					<button
            className="uiButton"
            onClick={() => setMenu(10)}
						// onClick={() =>
						// 	window.open(`https://thlbots.com/?type=play&lead_id=${leadData.lead_id}`, "_blank")
            // }
            
					>
						<span>{!language ? "play" : "Khelo"}</span>
					</button>
					<button
						className="uiButton uiButton_2"
						onClick={() => window.open(`https://t.me/${leadData.username}`, "_blank")}
					>
						{!language ? "HELP" : "Madad"}
					</button>
				</section>
			</div>
		</div>
	);
};

export default HowToPlay;
