import React, { useEffect, useState, useContext } from "react";
import css from "../../styles/OtherPages.module.scss";
import classNames from "classnames";
import numeral from "numeral";
import { Context } from "../../App";

const History = ({ menu, setMenu }) => {
	const { language } = useContext(Context);

	const [users, setUsers] = useState(0);
	const [winners, setWinners] = useState(0);
	const [rounds, setRounds] = useState([]);

	const [showRounds, setShowRounds] = useState(10);

	const [usersCount, setUsersCount] = useState();
	const [stage, setStage] = useState(0);
	const [winnersCount, setWinnersCount] = useState();
	const [stage2, setStage2] = useState(0);

	useEffect(() => {
		if (menu !== 4) return;
		getHistory();
	}, [menu]);

	useEffect(() => {
		const usersString = numeral(users).format("0,0");
		const timeoutId = setTimeout(() => {
			setStage(1);

			const timeoutId2 = setTimeout(() => {
				setUsersCount(usersString);
				setStage(0);
			}, 500);

			return () => clearTimeout(timeoutId2);
		}, 500);

		return () => clearTimeout(timeoutId);
	}, [users]);

	useEffect(() => {
		const winnersString = numeral(winners).format("0,0");
		const timeoutId2 = setTimeout(() => {
			setStage2(1);

			const timeoutId22 = setTimeout(() => {
				setWinnersCount(winnersString);
				setStage2(0);
			}, 500);

			return () => clearTimeout(timeoutId22);
		}, 500);

		return () => clearTimeout(timeoutId2);
	}, [winners]);

	const getHistory = async () => {
		const response = await fetch(`${process.env.REACT_APP_LINK}/api/odds/rounds-statistics`);
		if (!response.ok) {
			throw new Error("Network response was not ok");
		}
		const result = await response.json();
		setUsers(result.statistics.count_users);
		setWinners(result.statistics.amount_winnings);
		setRounds(result.history);
	};

	return (
		<div className={classNames(css.container, menu === 4 && css._show)}>
			<div className={css.content}>
				<section className={css.backContainer}>
					<button
						className={css.back}
						onClick={() => setMenu(1)}
					>
						SOFT
					</button>
				</section>

				<section className={css.history}>
					<div className={css.historyHeader}>
						<div className={css.historyStats}>
							<div className={css.sec1}>
								{!language && (
									<div className={css.titles}>
										<span>HISTORY</span>
										<span>ROUND</span>
									</div>
								)}
								{!!language && (
									<div className={css.titles}>
										<span>Rounds</span>
										<span>ka itihaas</span>
									</div>
								)}
							</div>
							<div className={classNames(css.sec2, stage === 1 && css._rotate)}>
								<div className={css.titles}>
									<span>{numeral(usersCount).format("0,0")}</span>
									<span>users</span>
								</div>
							</div>
							<div className={classNames(css.sec3, stage2 === 1 && css._rotate)}>
								<div className={css.titles}>
									<span>{numeral(winnersCount).format("0,0")}₹</span>
									{!language && <span>winnings</span>}
									{!!language && <span>jeet</span>}
								</div>
							</div>
						</div>
					</div>
					<div className={css.historyBody}>
						<div
							className={classNames(css.historyStats, css._t2)}
							style={{ backgroundColor: "rgba(30, 31, 40, 1)" }}
						>
							<div
								className={classNames(css.sec2, css._t2)}
								style={{ width: "33.3333%" }}
							>
								<div className={classNames(css.titles, css._t2)}>
									<span className={css.usersTitle}>USERS</span>
								</div>
							</div>
							<div
								className={classNames(css.sec3, css._t2)}
								style={{ width: "33.3333%" }}
							>
								<div className={classNames(css.titles, css._t2)}>
									{!language && <span className={css.usersTitle}>WINNINGS</span>}
									{!!language && <span className={css.usersTitle}>JEET</span>}
								</div>
							</div>
							<div
								className={css.secCoef}
								style={{ width: "33.3333%" }}
							>
								<div className={css.titleResult}>{!language ? "Result" : " Natija"}</div>
							</div>
						</div>
					</div>
					<div className={css.historyBody}>
						{rounds.map((item, index) => {
							return (
								<React.Fragment key={item.id}>
									{index + 1 <= showRounds && (
										<div className={classNames(css.historyStats, css._t2)}>
											<div
												className={classNames(css.sec2, css._t2)}
												style={{ width: "33.3333%" }}
											>
												<div className={classNames(css.titles, css._t2)}>
													<span className={css.usersTitle}>
														{numeral(item.users).format("0,0")}
													</span>
												</div>
											</div>
											<div
												className={classNames(css.sec3, css._t2)}
												style={{ width: "33.3333%" }}
											>
												<div className={classNames(css.titles, css._t2)}>
													<span className={css.usersTitle}>
														{numeral(item.winners).format("0,0")}₹
													</span>
												</div>
											</div>
											<div
												className={css.secCoef}
												style={{ width: "33.3333%" }}
											>
												<div
													className={classNames(
														css.coeff,
														item.coefficient >= 1 && item.coefficient <= 1.99 && css._blue,
														item.coefficient >= 2 && item.coefficient <= 9.99 && css._purple,
														item.coefficient >= 10 && item.coefficient <= 99.99 && css._pink,
														item.coefficient >= 100 && css._gold,
													)}
												>
													{numeral(item?.coefficient).format("0.00")}x
												</div>
											</div>
										</div>
									)}
								</React.Fragment>
							);
						})}
						{rounds.length > 10 && showRounds <= rounds.length && (
							<button
								className={css.showMore}
								onClick={() => setShowRounds(prev => prev + 10)}
							>
								{!language ? 'Show more' : "Aur dikhaye"}
							</button>
						)}
					</div>
				</section>
			</div>
		</div>
	);
};

export default History;
