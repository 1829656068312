/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import css from "../styles/InstallPage.module.scss";
import classNames from "classnames";
import IOS from "./IOS";

const comments = [
	{
		image: "1.jpg",
		name: "Raj Reddy",
		rating: 5,
		date: "17/09/2024",
		text: "Bhai, yeh app toh kamaal ki hai! Ab main jaldi cash out kar leta hoon aur zyada jeet raha hoon.",
	},
	{
		image: "2.jpg",
		name: "Aditi Banerjee",
		rating: 5,
		date: "17/09/2024",
		text: "Super useful app! Love the 'Increase Chance' feature. It actually works, and I’ve started winning more often.",
	},
	{
		image: "3.jpg",
		name: "Saanvi Singh",
		rating: 5,
		date: "16/09/2024",
		text: "App ka previous rounds wala feature bohot helpful hai. Mujhe ab pata chalta hai kab best chance hai cash out karne ka.",
	},
	{
		image: "4.jpg",
		name: "Ishaan Reddy",
		rating: 5,
		date: "16/09/2024",
		text: "This app has totally changed how I play Aviator. Now I feel in control, and my wins are increasing every day!",
	},
	{
		image: "5.jpg",
		name: "Aditi Khan",
		rating: 5,
		date: "15/09/2024",
		text: "Yeh app sach mein meri winning strategy ka part ban gaya hai. Ab previous rounds ko dekh ke zyada confident lagta hai.",
	},
	{
		image: "6.jpg",
		name: "Dhruv Gupta",
		rating: 5,
		date: "15/09/2024",
		text: "I never thought an app could make such a difference in my gameplay. The 'Increase Chance' feature is a game-changer!",
	},
	{
		image: "7.jpg",
		name: "Dhruv Gupta",
		rating: 5,
		date: "15/09/2024",
		text: "Pehle game mein hamesha haar jaata tha, lekin ab is app ke saath mai consistently jeet raha hoon.",
	},
	{
		image: "8.jpg",
		name: "Dhruv Gupta",
		rating: 5,
		date: "15/09/2024",
		text: "If you're serious about winning in Aviator, this app is a must! It gives you the edge you need to stay ahead.",
	},
	{
		image: "9.jpg",
		name: "Dhruv Gupta",
		rating: 5,
		date: "15/09/2024",
		text: "Mujhe kabhi pata nahi chalta tha kab cash out karna hai. Ab app se easily track kar paata hoon aur zyada wins ho rahe hain.",
	},
	{
		image: "10.jpg",
		name: "Riya Prasad",
		rating: 5,
		date: "15/09/2024",
		text: "I've been using this app for a few weeks, and it has significantly improved my chances of winning. Highly recommended!",
	},
];

const Install = ({ device }) => {
	const [status, setStatus] = useState("download");
	const [progress, setProgress] = useState(0);
	const [iosActive, setIosActive] = useState(false);
	const duration = 12000; // 12 секунд
	const totalSteps = 30; // Кількість оновлень прогресу
	const intervalTime = duration / totalSteps; // Час між оновленнями (мс)

	window.isPWA = function () {
		return (
			window.matchMedia("(display-mode: standalone)").matches ||
			window.navigator.standalone ||
			document.referrer.includes("android-app://")
		);
	};

	useEffect(() => {
		if (status !== "downloading") return;
		let steps = 0;

		const interval = setInterval(() => {
			setProgress(prevProgress => {
				const randomIncrease = Math.floor(Math.random() * 5) + 1; // випадкове число від 1 до 5
				const newProgress = Math.min(prevProgress + randomIncrease, 100); // максимум 100%
				return newProgress;
			});

			steps += 1;

			if (steps >= totalSteps || progress >= 100) {
				setStatus("install");
				clearInterval(interval); // Зупиняємо, коли досягли 100% або кінець кроків
			}
		}, intervalTime);

		return () => clearInterval(interval); // Чистка інтервалу при розмонтуванні
	}, [progress, status]);

	useEffect(() => {
		window.addEventListener("beforeinstallprompt", async function (e) {
			e.preventDefault();
			setStatus("install");
			window.deferredPrompt = e;
			console.log(e);
		});

		let intervalId = setInterval(async () => {
			let appInstalled = await navigator.getInstalledRelatedApps();

			if (appInstalled.length > 0) {
				setStatus("open");

				clearInterval(intervalId);
			}
		}, 500);
	}, []);

	const handleDownload = () => {
		if (device.deviceType === "Desktop") {
			return setStatus("install");
		}
		if (device.deviceType === "IOS") {
			const body = document.querySelector("body");
			body.scrollTo(0, 0);

			return setIosActive(true);
		}
		if (device.deviceType === "Android") {
			setStatus("downloading");
		}
	};

	const handleInstall = () => {
		setStatus("installing");
		if (window.deferredPrompt != null) {
			window.deferredPrompt.prompt();
		}
	};

	const handleOpen = () => {
		if (window.isPWA()) {
			window.open("/pwa", "_self");
		} else {
			window.open(`https://aviasoft.pro/pwa`, "_blank");
		}
	};

	return (
		<>
			<div className={classNames(css.androidContainer, iosActive && css._hidden)}>
				<div className={css.androidContent}>
					<section className={css.logoSection}>
						<div className={css.logo} />
					</section>
					<section className={css.infoSection}>
						<div className={css.info}>
							<div className={css.reviews}>
								<span>4.7</span>
								<span>13K reviews ⓘ</span>
							</div>
							<div className={css.size}>
								<span></span>
								<span>12.2 MB</span>
							</div>
							<div className={css.downloads}>
								<span>324k</span>
								<span>Downloads</span>
							</div>
						</div>
					</section>
					<section className={css.downloadSection}>
						{status === "download" && (
							<button
								className={classNames(css.button, css.buttonDownload)}
								onClick={handleDownload}
							>
								<span>Download</span>
							</button>
						)}
						{status === "downloading" && (
							<button className={classNames(css.button, css.buttonDownloading)}>
								<span>Download</span>
								<div
									className={css.downloading}
									style={{ width: `${progress}%` }}
								/>
							</button>
						)}
						{status === "installing" && (
							<button className={classNames(css.button, css.buttonInstalling)}>
								<span>Installing...</span>
								<div className={css.installing} />
							</button>
						)}
						{status === "install" && (
							<button
								className={classNames(css.button, css.buttonInstall)}
								onClick={handleInstall}
							>
								<span>Install</span>
							</button>
						)}
						{status === "open" && (
							<button
								className={classNames(css.button, css.buttonInstall)}
								onClick={handleOpen}
							>
								<span>Open</span>
							</button>
						)}
					</section>
					<section className={css.sliderSection}>
						<div className={css.slider}>
							<img
								className={css.slide}
								src="/images/slideForInstall/1.png"
								alt="#"
							/>
							<img
								className={css.slide}
								src="/images/slideForInstall/2.png"
								alt="#"
							/>
							<img
								className={css.slide}
								src="/images/slideForInstall/3.png"
								alt="#"
							/>
						</div>
					</section>
					<section className={css.descriptionSection}>
						<span className={css.title}>About this app</span>
						<p className={css.text}>
							Aviator Soft is your tool for confident wins in "Aviator". Predict multipliers,
							increase your balance, and activate enhanced chances to win with the "Increase Chance"
							feature. Analyze previous round results to choose the best moments for your bets. The
							higher your balance, the greater your chances of success. Download Aviator Soft now to
							take control of the game and earn more in every round!
						</p>
					</section>
					<section className={css.descriptionSection}>
						<span className={css.title}>Rating and reviews</span>
						<p className={css.text}>
							Ratings and reviews have been verified. They were made by users with the same type of
							device as yours.
						</p>
					</section>
					<section className={css.ratingSection}>
						<div className={css.rating}>
							<span className={css.num}>4.7</span>
							<span className={css.stars} />
							<span className={css.reviews}>13,345</span>
						</div>
						<div className={css.lines}>
							<div className={css.lineBg}>
								<div className={css.line} />
							</div>
							<div className={css.lineBg}>
								<div className={css.line} />
							</div>
							<div className={css.lineBg}>
								<div className={css.line} />
							</div>
							<div className={css.lineBg}>
								<div className={css.line} />
							</div>
							<div className={css.lineBg}>
								<div className={css.line} />
							</div>
						</div>
					</section>
					<section className={css.commentsSection}>
						{comments.map(item => {
							return (
								<div
									className={css.comment}
									key={`${item.text}-${item.name}`}
								>
									<div className={css.header}>
										<div className={css.user}>
											<div
												className={css.avatar}
												style={{ backgroundImage: `url("images/users/${item.image}")` }}
											/>
											<div className={css.info}>
												<span className={css.name}>{item.name}</span>
												<span className={css.date}>{item.date}</span>
											</div>
										</div>
										<div className={css.rating} />
									</div>
									<div className={css.body}>
										<p className={css.comment}>{item.text}</p>
									</div>
								</div>
							);
						})}
					</section>
				</div>
			</div>
			<IOS
				iosActive={iosActive}
				setIosActive={setIosActive}
			/>
		</>
	);
};

export default Install;
